import styled from 'styled-components'

const Form = styled.form`
  display: grid;
  grid-row-gap: 1em;
  width: 100%;
  max-width: 480px;
  padding: 2rem;
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
`

export default Form
