import React from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'

import Login from '../components/Login'
import api from '../services/api'

const IndexPage = () => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = data => {
    api.login(data)
  }

  if (api.token) {
    navigate('/app')
  }
  return (
    <Login
      useForm={{
        register: register,
        handleSubmit: handleSubmit,
        errors: errors,
      }}
      submitHandler={onSubmit}
    ></Login>
  )
}

export default IndexPage
