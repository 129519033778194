import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  appearance: none;
  padding: calc(1rem - 1px);
  color: var(--white);
  font-weight: 700;
  line-height: 100%;
  background: var(--blue);
  border: 1px solid var(--dark-blue);
  border-radius: 0.5rem;
  outline: 0;
  cursor: pointer;
  transition: background 0.2s, border 0.2s;

  &:disabled {
    color: var(--dark-gray);
    background: var(--light-gray);
    border-color: var(--light-gray);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: var(--light-blue);
  }

  &:focus {
    box-shadow: 0 0 0 2px orange;
  }
`

const Button = ({ children, type, disabled }) => {
  return (
    <StyledButton type={type} disabled={disabled}>
      {children}
    </StyledButton>
  )
}

export default Button
