import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  text-align: center;

  a {
    color: currentColor;
    text-decoration: none;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      © {new Date().getFullYear()},{` `}
      <a
        href="https://www.alphatour.ru"
        target="_blank"
        rel="noopener noreferrer"
      >
        Альфа-Тур
      </a>
    </StyledFooter>
  )
}

export default Footer
