import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;

  & > *:last-child {
    margin-top: auto;
    margin-bottom: 2rem;
  }
`

export default Wrapper
