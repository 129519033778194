import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Logo from './Logo'

const StyledHeader = styled.header`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  padding-top: 4rem;
`

const Header = () => (
  <StyledHeader>
    <Logo />
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
