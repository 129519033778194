import React from 'react'

import '../layout.css'
import Wrapper from './Wrapper'
import SEO from '../shared/seo'
import Fonts from '../shared/Fonts'
import Header from './Header'
import Form from './Form'
import Input from './Input'
import Button from '../shared/Button'
import Footer from './Footer'

const Login = ({ useForm, submitHandler }) => {
  const { register, handleSubmit, errors } = { ...useForm }
  return (
    <Wrapper>
      <SEO title="Авторизация" />
      <Fonts />
      <Header />
      <main>
        <Form onSubmit={handleSubmit(submitHandler)}>
          <Input
            type="text"
            name="login"
            label="Логин"
            register={register({ required: true })}
            error={errors.login}
            errorText="Введите логин"
          />
          <Input
            type="password"
            name="password"
            label="Пароль"
            register={register({ required: true })}
            error={errors.password}
            errorText="Введите пароль"
          />
          <Button type="submit">Войти</Button>
        </Form>
      </main>
      <Footer />
    </Wrapper>
  )
}

export default Login
