import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
  position: relative;
  display: flex;
`

const Caption = styled.div`
  position: absolute;
  top: calc(50% - 0.5em);
  left: 1em;
  color: ${props => (props.error ? 'var(--red)' : 'var(--dark-gray)')};
  line-height: 100%;
  transition: top 0.2s, left 0.2s, font-size 0.2s;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(100% + 0.5em);
    height: calc(100% + 0.25em);
    background: var(--white);
    border-radius: 0.25em;
    transform: translate(-50%, -50%);
  }

  input:focus + &,
  input:not(:placeholder-shown) + & {
    top: -0.5em;
    left: 1.5em;
    color: ${props => (props.error ? '' : 'var(--blue)')};
    font-size: 0.75em;
  }

  span {
    position: relative;
  }
`

const StyledInput = styled.input`
  appearance: none;
  width: 100%;
  padding: calc(1em - 1px);
  line-height: 100%;
  background: var(--white);
  border: 1px solid transparent;
  border-color: ${props => (props.error ? 'var(--red)' : 'var(--light-gray)')};
  border-radius: 0.5em;
  outline: none;
  transition: border 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:hover {
    border-color: ${props => (props.error ? '' : 'var(--dark-gray)')};
  }

  &:focus {
    border-color: ${props => (props.error ? '' : 'var(--blue)')};
  }
`

const Input = props => {
  const type = props.type
  const name = props.name
  const label = props.label
  const register = props.register
  const error = props.error
  const errorText = props.errorText

  return (
    <Label>
      <StyledInput
        type={type}
        name={name}
        placeholder={label}
        ref={register}
        error={error}
      />
      <Caption error={error}>
        <span>{error ? errorText : label}</span>
      </Caption>
    </Label>
  )
}

export default Input
